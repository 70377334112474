<template>
  <v-container>
    <div class="py-4">
      <!-- <v-btn @click="joinConversation">Join</v-btn>
    <v-btn @click="dummySend">Send</v-btn> -->
      <div>Conversation Id : {{ currentId }}</div>

      <v-progress-circular
        indeterminate
        color="primary"
        v-if="loading"
      ></v-progress-circular>

      <div v-else>
        <v-row v-if="messages.length > 0">
          <v-col md="9" class="mr-0 pr-0 p-0">
            <div style="position: relative">
              <div class="chat">
                <div class="chat-box" v-chat-scroll>
                  <div
                    class="message-item"
                    v-for="(message, index) in messages"
                    :class="'message-' + index"
                    :key="index"
                  >
                   
                    <!-- <v-avatar color="indigo" size="36">
                      <span class="white--text text-h5">M</span>
                    </v-avatar> -->
                    <div>
                    <div
                      class="forwarded-caption"
                      v-if="message.direction == 'MODERATOR_EXPERT'"
                    >
                      <v-icon>mdi-share-outline</v-icon>
                      Moderator Forwarded a Message
                    </div>

                    <div
                      class="message-text"
                      :class="getMessageClass(message.direction)"
                    >
                      <div class="msg-media" v-if="message.imagePath">
                        <a :href="message.imagePath" target="_blank">
                          <img
                            :src="message.imagePath"
                            alt=""
                            v-if="
                              checkFileType(message.imagePath) == 'jpeg' ||
                              checkFileType(message.imagePath) == 'jpg' ||
                              checkFileType(message.imagePath) == 'png'
                            "
                            style="
                              height: 100px;
                              display: block;
                              margin-bottom: 10px;
                            "
                          />
                        </a>
                        <div v-if="checkFileType(message.imagePath) == 'pdf'">
                          <a :href="message.imagePath" target="_blank"
                            >Download Pdf</a
                          >
                        </div>
                      </div>

                      {{ message.message }}

                      <div class="message-date">
                        {{ formatDate(message.dateTime, "MM DD :A ") }}
                      </div>
                    </div>
                    </div>

                    <div style="clear: both"></div>
                  </div>
                </div>
                <div class="type-message">
                  <div v-for="(preview, index) in previewImage" :key="index">
                    <!-- <img :src="preview" alt="" class="preview-image" />
              <button @click="removeFile(index)">Remove</button>
              <v-icon color="red" @click="removeFile(index)"
                >mdi-trash-can-outline</v-icon
              > -->

                    <v-badge color="#fff" overlap>
                      <img :src="preview" alt="" class="preview-image" />
                      <template v-slot:badge>
                        <v-icon color="red" @click="removeFile(index)"
                          >mdi-trash-can-outline</v-icon
                        >
                      </template>
                    </v-badge>
                  </div>
                  <v-row>
                    <!-- <v-col md="1">
                  <input
                    id="fileUpload"
                    type="file"
                    hidden
                    ref="fileUpload"
                    @input="pickFile"
                  />
                  <v-icon large @click="openFileUpload">
                    mdi-attachment
                  </v-icon>
                </v-col> -->
                    <v-col>
                      <div class="active-chat-label">Active Chat</div>

                      <div>
                        {{ messages[activeChatIndex].text }}
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Type Your Message"
                        type="text"
                        outlined
                        dense
                        hide-details="auto"
                        required
                        v-model="typeMessage"
                      ></v-text-field>
                    </v-col>

                    <!-- <v-col cols="2">
                  <v-btn color="primary" @click="sendMessage" fab small>
                    <v-icon class="" dark>mdi-send</v-icon>
                  </v-btn>
                </v-col> -->
                  </v-row>
                  <div class="chat-actions">
                    <div class="files-attachment">
                      <input
                        id="fileUpload"
                        type="file"
                        hidden
                        ref="fileUpload"
                        @input="pickFile"
                      />
                      <v-icon large @click="openFileUpload">
                        mdi-attachment
                      </v-icon>
                    </div>

                    <div>
                      <v-btn small color="blue">Get Help From Expert</v-btn>

                      <v-btn
                        small
                        color="primary"
                        class="mr-0"
                        @click="sendMessage"
                        >Send
                        <v-icon class="pl-2" dark small>mdi-send</v-icon></v-btn
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>

          <v-col md="3" class="ml-0 pl-0 p-0">
            <div class="profile">
              <div class="profile-img">
                <v-img
                  src="https://media.istockphoto.com/photos/portrait-of-smiling-handsome-man-in-blue-tshirt-standing-with-crossed-picture-id1045886560?k=20&m=1045886560&s=612x612&w=0&h=JL8Dy_sRUXJo6PofsX7XkQpWjSTDhD8LuV071LMlb3Y="
                  height="100%"
                ></v-img>
              </div>
              <h3 class="text-center mt-3">{{ ownDetail.name }}</h3>

              <v-container>
                <div
                  class="d-flex flex-column align-start justify-content-center"
                >
                  <div class="d-flex">
                    <div class="item-title">Phone</div>
                    <div class="item-content">{{ ownDetail.phone }}</div>
                  </div>

                  <div class="d-flex">
                    <div class="item-title">Email</div>
                    <div class="item-content">{{ ownDetail.email }}</div>
                  </div>
                </div>
              </v-container>
            </div>
          </v-col>
        </v-row>
        <div class="no-message" v-else>
          <v-card outlined class="">
            <v-card-text class="text-center">
              <v-icon large>mdi-android-messages</v-icon>
              <h4 class="display-2 mt-3">You Dont Have Any Message Yet</h4>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
  </v-container>
</template>


<script>
import CustomerAPi from "../../api/message";

export default {
  sockets: {
    connect() {
      console.log("socket connected");

      this.joinConversation();
    },
    // customEmit(val) {
    //   console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
    // },
    // conversationLisenter(){
    //   console.log('ace');
    // }
  },
  data() {
    return {
      loading: true,
      typeMessage: "",
      activeChatIndex: 0,
      currentId: "",
      currentMessageActivity: "",
      messages: [],
      previewImage: [],
      photos: [],
      // ownDetails
      ownDetail: {},
      //
    };
  },
  methods: {
    test() {
      alert("hello");
    },
    getMessageClass(val) {
      if (val == "MODERATOR_CUSTOMER") {
        // MODERATER REPLING TO CUSTOMER
        return "float-right";
      } else if (val == "MODERATOR_EXPERT") {
        // FORWARDED MESSAGE
        return "float-left moderator";
      } else if (val == "EXPERT_MODERATOR") {
        // EXPERT's REPLY
        return "float-right";
      } else if (val == "EXPERT_EXPERT") {
        // EXPERT ASKING ANOTHER DEPARTMENT
        return "float-right";
      } else {
        // CUSTOMER_MODERATOR
        return "float-left customer";
      }
    },
    connectSocket() {
      let accessToken = this.$store.getters.loggedInUser.accessCode.accessToken;
      this.$socket.client.io.opts.extraHeaders.Authorization = accessToken;
      this.$socket.client.open();
      this.subscribeListeners();
    },
    joinConversation() {
      console.log("join conversation", this.currentId);
      this.$socket.client.emit("joinConversation", {
        conversationId: this.currentId,
      });
    },
    subscribeListeners() {
      console.log("Subscribe listeners");
      let vm = this;
      this.$socket.client.on("chat-listener", function (e) {
        console.log("Chat listeners", e);
        vm.messages.push({
          message: e.message,
          dateTime: e.dateTime,
          id: e.id,
          direction: e.direction,
        });
      });
      this.$socket.client.on("conversation-listener", function (e) {
        console.log("Conversation LISTENER CHAT:", e);
        vm.messages = e.data.chatMessages;

        vm.loading = false;
      });

      this.$socket.client.on("active-conversation-listener", function (e) {
        console.log("Active Chat Listener", e);
      });
    },

    sendMessage() {
      //   let id = this.items[this.selected].bookingId;
      let details = {
        message: this.typeMessage,
        conversationId: this.currentId,
        messageActivityId: this.currentMessageActivity,
      };

      this.$socket.client.emit("expertReply", details);

      // this.activeChatIndex++;
      this.typeMessage = "";
    },

    getMessages() {
      this.$MessageApi
        .getMessages()
        .then((data) => {
          console.log(data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getActiveChat() {
      this.activeChat = this.messages[0];
    },

    getDepartments() {
      CustomerAPi.getDepartment()
        .then((data) => {
          console.log(data.data.data);
          this.departments = data.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    scrollToElement() {
      let index = this.items.length;
      let className = `message-${index}`;
      const el = this.$el.getElementsByClassName(className)[0];
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    openFileUpload() {
      console.log(this.$refs.fileUpload.click());
    },
    pickFile() {
      let input = this.$refs.fileUpload;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage.push(e.target.result);
        };
        reader.readAsDataURL(file[0]);
        this.photos.push(file[0]);
      }
    },
    selectImage() {},
    removeFile() {},
    checkFileType(file) {
      return file.split(".").pop();
    },

    // OwnDetails
    getOwnDetails() {
      CustomerAPi.getOwnDetail()
        .then((data) => {
          console.log(data.data.data);
          this.ownDetail = data.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  created() {},

  mounted() {
    this.getDepartments();
    this.getOwnDetails();
    this.currentMessageActivity = this.$route.query.messageActivity;
    this.currentId = this.$route.params.id;

    setTimeout(() => {
      this.connectSocket();
    }, 2000);
  },
};
</script>

<style scoped>
.chat-box {
  background: #fff;
}

.chat-box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.chat-box::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.chat-box::-webkit-scrollbar-thumb {
  background-color: #000000;
}

.chat {
  height: 550px;
  border: 1px solid #ccc;
}

.chat-box {
  height: 60%;
  overflow-y: scroll;
  padding: 0 15px;
}

.type-message {
  /* position: absolute; */
  height: 40%;
  overflow: hidden;
  bottom: 0;
  width: 100%;
  /* margin-top: 10px; */
  min-height: 60px;
  padding: 10px 15px;
  background: #fff;
  /* padding: 0 15px; */
}

.active-chat-label {
  font-weight: 600;
}

.chat-actions {
  margin-top: 15px;
  padding-top: 5px;
  padding-bottom: 6px;
  display: flex;
  justify-content: space-between;
}

.profile {
  background: #fff;
  height: 100%;
  padding-top: 20px;
  border-left: 1px solid #f5f5f5;
}

.profile .profile-img {
  height: 180px;
  width: 180px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto;
}

.details {
  min-height: 550px;
  padding: 20px 20px;
  border: 1px solid #ccc;
  border-left: 0;
}

.message-item {
  margin-top: 15px;
}

.message-text {
  font-size: 14px;
  max-width: 300px;
  background: #e8f5e9;
  color: #222;
  padding: 15px;
  display: block;
  border-radius: 15px;
}

.message-date {
  font-size: 10px;
  text-align: right;
  color: #fff;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.selected-chat {
  background: #f5f5f5;
}

.preview-image {
  height: 70px;
  padding: 10px 0;
}

.message-item:last-child {
  margin-bottom: 100px;
}

.no-message {
  min-height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-title {
  min-width: 60px;
  text-align: right;
  color: rgb(158, 157, 157);
  font-weight: 600;
}

.item-content {
  padding-left: 20px;
  font-weight: 400;
}

.moderator {
  background: #0084ff;
  color: #fff;
}

.customer {
  background: #67b868;
  color: #fff;
}

.forwarded-caption {
  font-size: 12px;
  color: #8a8d91;
}
</style>